.ui-dialog {
	background-color: $panelContentBgColor;
	color: $panelContentTextColor;
	margin: 0;
	border: $overlayContentBorder;
	min-width: 100px;
	@include border-radius(4px);
	@include shadow((0 4px 8px 0 rgba(17, 43, 78, 0.16), 0 0 2px 0 rgba(17, 43, 78, 0.08)));

	.ui-dialog-titlebar {
		font-weight: $panelHeaderFontWeight;
		border: none;
		padding: 16px 20px 4px;

		.ui-dialog-title {
			margin-bottom: 4px;
			font-family: $fontFamilyFordAntennaMedium;
			font-size: 14px;
			font-weight: 500;
			line-height: 1.43;
		}

		.ui-dialog-titlebar-icon {
			color: $panelHeaderIconColor;
			position: absolute;
			top: 12px;
			right: 12px;
			width: 12px;
			height: 12px;
			line-height: 12px;
			@include border-radius(0);

			&:hover {
				color: $overlayPanelCloseIconHoverColor;
			}

			&:focus {
				@include shadow(none);
			}
		}
	}

	.ui-dialog-content {
		padding: 0 20px 16px;
		font-family: $fontFamilyFordAntennaLight;
		font-weight: 300;
		font-size: 14px;
		line-height: 1.43;
		background-color: transparent;
	}
}
