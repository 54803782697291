$icon-font-family: "app-icons" !default;
$icon-font-path: "fonts" !default;

$icon-check: "\e90a";
$icon-link: "\e90b";
$icon-add: "\e90c";
$icon-arrow-down: "\e905";
$icon-arrow-up: "\e906";
$icon-edit: "\e907";
$icon-trash: "\e908";
$icon-info: "\e909";
$icon-filter: "\e903";
$icon-download: "\e902";
$icon-search: "\e904";
$icon-chevron-left: "\e900";
$icon-chevron-right: "\e901";
