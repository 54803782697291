.ui-sidebar {
	width: 448px;
	@include shadow((-4px 0 18px 8px rgba(7, 26, 83, 0.08), -1px 0 1px 0 rgba(15, 43, 79, 0.04)));
	@include border-radius((8px 0 0 8px));
	color: $primaryColor;
	overflow: visible;

	.ui-sidebar-close {
		margin: 20px 18px;
		padding: 0;
		height: 20px;
		width: 20px;
		font-size: 18px;
		color: $overlayPanelCloseIconColor;
		position: absolute;
		top: 0;
		right: 0;

		&:hover {
			color: $overlayPanelCloseIconHoverColor;
		}
	}
}

.btn-sidebar {
	display: flex;
	align-items: center;
	justify-content: center;

	button {
		background: none;
		color: $FDS-ford-blue;

		&:enabled {
			&:focus {
				box-shadow: none;
			}
		}
	}

	.ui-button {
		border: none;
		box-shadow: none;

		&:hover {
			border: none !important;
			box-shadow: none !important;
			background: $white !important;
			color: $primaryDarkColor !important;

			.ui-button-icon-left {
				transform: scale(1.125);
				transition: transform 0.1s ease-in-out;
			}
		}
	}
}

@media screen and (min-width: $breakpoint-md) {
	.btn-sidebar {
		display: none;
	}
}

&.mobile-mode .ui-sidebar {
	width: 100%;
	border-radius: 0;
}
