.ui-toast {
	.ui-toast-message {
		color: $panelContentTextColor;
		border: $overlayContentBorder;
		min-width: 100px;
		@include border-radius(4px);
		@include shadow($toastShadow);
		margin: 10px 0 0 0;

		.ui-toast-icon {
			display: none;
		}

		.ui-toast-message-text-content {
			margin: 0;
		}

		.ui-toast-summary {
			border: none;
			padding: 16px 0 4px 0;
			font-family: $fontFamilyFordAntennaMedium;
			font-size: 14px;
			font-weight: 500;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.43;
			text-transform: capitalize;
		}

		.ui-toast-detail {
			font-family: $fontFamilyFordAntennaLight;
			font-size: 18px;
			font-weight: 300;
			line-height: 1.5;

			&.description {
				font-family: $fontFamilyFordAntennaLight;
				font-weight: 300;
				font-size: 14px;
			}
		}

		.ui-toast-message-content {
			padding: 0 20px 16px;
			font-family: $fontFamilyFordAntennaLight;
			font-weight: 300;
			font-size: 14px;
			line-height: 1.43;
			background-color: transparent;
		}

		&.ui-toast-message-info,
		&.ui-toast-message-error,
		&.ui-toast-message-success {
			background-color: $successMessageBgColor;
			border: $successMessageBorder;
			color: $primaryColor;

			.ui-toast-close-icon {
				color: $panelHeaderIconColor;
				position: absolute;
				top: 12px;
				right: 12px;
				width: 12px;
				height: 12px;
				line-height: 12px;
				@include border-radius(0);

				&:hover {
					color: $overlayPanelCloseIconHoverColor;
				}

				&:focus {
					@include shadow(none);
				}
			}
		}

	}
}

.mobile-mode .ui-toast {
	width: 100%;
	bottom: 0;
	left: 0;

	.ui-toast-message {
		border-bottom-left-radius: 0;
		border-bottom-right-radius: 0;
	}
}
