.ui-button {
	// primary button
	border: none;
	box-shadow: none;
	@include border-radius(0);
	height: 60px;
	font-family: $fontFamilyFordAntennaMedium;
	font-size: 14px;
	font-weight: 500;

	&:enabled:hover {
		background-color: $buttonHoverBgColor;
		color: $buttonHoverTextColor;
		box-shadow: none;
	}

	&:enabled:focus {
		outline: $buttonFocusOutline;
		outline-offset: $buttonFocusOutlineOffset;
		@include shadow($buttonFocusShadow);
		background-color: $buttonActiveBgColor;
	}

	&:enabled:active {
		background-color: $buttonActiveBgColor;
		color: $buttonActiveTextColor;
		box-shadow: none;
	}

	&:disabled {
		background-color: $FDS-light-gray;
		color: $overlayPanelCloseIconColor;
		box-shadow: none;
		cursor: default;
	}

	// tool header button
	&.tool-button {
		height: 36px;
		background-color: #163865;

		&:enabled:hover {
			background-color: #0475b3;
			box-shadow: none;
		}

		&:enabled:active {
			background-color: #0475b3;
			box-shadow: none;
		}
	}

	&.ui-button-rounded {
		@include border-radius($roundedButtonBorderRadius);
	}

	&.management-search-button {
		min-width: 40px;
		height: 40px;
		margin: 0;
	}

	// secondary button
	&.ui-button-secondary {
		font-family: $fontFamilyFordAntennaMedium;
		font-size: 14px;
		font-weight: 500;

		&:enabled:hover {
			background-color: $buttonHoverBgColor;
			color: $buttonHoverTextColor;
			box-shadow: none;
		}

		&:disabled {
			background-color: $FDS-light-gray;
			color: $overlayPanelCloseIconColor;
			box-shadow: none;
			cursor: default;
		}
	}
}

.ui-button-icon-only {
	.ui-button-icon-left {
		font-size: 1em;
	}
}

.p-button-outlined.ui-button:enabled {
	color: $buttonBgColor;
	background: $transparent;
	border: 1px solid $buttonBgColor;

	&:focus {
		@include shadow(none)
	}

	&:hover {
		color: $buttonHoverBgColor;
		border: 1px solid $buttonHoverBgColor;
	}
}

.ui-button.ui-state-default.ui-button-danger, .ui-buttonset.ui-button-danger > .ui-button.ui-state-default {
	&:disabled {
		background-color: $FDS-light-gray;
		color: $overlayPanelCloseIconColor;
		box-shadow: none;
		cursor: default;
	}
}
