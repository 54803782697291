@import "variables";

@font-face {
	font-family: '#{$icon-font-family}';
	src: url('#{$icon-font-path}/#{$icon-font-family}.eot?7jmlxl');
	src: url('#{$icon-font-path}/#{$icon-font-family}.eot?7jmlxl#iefix') format('embedded-opentype'),
	url('#{$icon-font-path}/#{$icon-font-family}.ttf?7jmlxl') format('truetype'),
	url('#{$icon-font-path}/#{$icon-font-family}.woff?7jmlxl') format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: '#{$icon-font-family}' !important;
	speak: never;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon-check {
	&:before {
		content: $icon-check;
	}
}

.icon-link {
	&:before {
		content: $icon-link;
	}
}

.icon-add {
	&:before {
		content: $icon-add;
	}
}

.icon-arrow-down {
	&:before {
		content: $icon-arrow-down;
	}
}

.icon-arrow-up {
	&:before {
		content: $icon-arrow-up;
	}
}

.icon-edit {
	&:before {
		content: $icon-edit;
	}
}

.icon-trash {
	&:before {
		content: $icon-trash;
	}
}

.icon-info {
	&:before {
		content: $icon-info;
	}
}

.icon-filter {
	&:before {
		content: $icon-filter;
	}
}

.icon-download {
	&:before {
		content: $icon-download;
	}
}

.icon-search {
	&:before {
		content: $icon-search;
	}
}

.icon-chevron-left {
	&:before {
		content: $icon-chevron-left;
	}
}

.icon-chevron-right {
	&:before {
		content: $icon-chevron-right;
	}
}
