/*
Provide any app specific custom or override typographic rules you want
above and beyond what Web Enablement provides in this file
 */

h1, .h1, h2, .h2, h3, .h3, h4, .h4 {
	font-family: $fontFamilyFordAntennaMedium;
	font-weight: 500;
	line-height: 2.67;
	letter-spacing: 0.25px;
	text-transform: uppercase;
	margin: 0;
}

h5, .h5, h6, .h6 {
	font-family: $fontFamilyFordAntenna;
	font-style: normal;
	font-weight: 400;
}

h3, .h3 {
	font-size: 1.18rem;
}

h4, .h4 {
	font-size: 1rem;
}

p {
	margin: 0.5em;
}

hr {
	margin-bottom: 1em;
}

a {
	&:hover {
		color: $primaryDarkColor;
	}
}

hr {
	border: 0;
	border-top: 1px solid lighten($FDS-disabled-dark_light-background, 25%);
}

h2 {
	font-size: 12px;
}
