.ui-selectbutton {
	display: flex;

	.ui-button {
		background-color: $toggleButtonBgColor;
		border: $toggleButtonBorder;
		color: $toggleButtonTextColor;
		@include transition(none);
		@include border-radius(4px);
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		height: 36px;
		margin: 0 10px 0 0;
		@include shadow(none);

		&:not(.ui-state-disabled):not(.ui-state-active).ui-state-focus,
		&:not(.ui-state-disabled).ui-state-focus {
			@include shadow(none);
		}

		&:not(.ui-state-disabled):not(.ui-state-active):hover {
			background-color: $toggleButtonHoverBgColor;
			border: solid 1px $primaryColor;
			color: $toggleButtonHoverTextColor;
			@include shadow(none);
		}

		&.ui-state-active {
			background-color: $toggleButtonActiveBgColor;
			border: $toggleButtonBorder;
			color: $toggleButtonTextColor;
			@include shadow(none);

			&:not(.ui-state-disabled):hover {
				background-color: $toggleButtonActiveBgColor;
				border: $toggleButtonBorder;
				color: $toggleButtonTextColor;
				@include shadow(none);
			}
		}

		&:last-child {
			margin: 0;
		}
	}
}
