.ui-inputswitch {
	width: $inputSwitchWidth;
	height: $inputSwitchHeight;

	.ui-inputswitch-slider {
		&:before {
			height: 16px;
			width: 16px;
			left: 2px;
			bottom: 2px;
		}
	}

	&.ui-inputswitch-checked {
		.ui-inputswitch-slider:before {
			-webkit-transform: translateX(20px);
			-ms-transform: translateX(20px);
			transform: translateX(20px);
		}
	}

	&.ui-inputswitch-focus {
		.ui-inputswitch-slider {
			@include shadow(none);
		}
	}
}
