/*
Provide any app specific custom or override main rules you want
above and beyond what Web Enablement provides in this file
 */
main,
#app-content {
	width: 100%;
	flex: 1 0 auto;
	background-color: #dfe2f2;
}
