/*
    Your App's theme is based on Web and Mobile Enablement's (WaME)'s webcore, which in turn is based on PrimeNG's Prime Designer API. This theme.scss file is the entry point.
    From here, we include:
      - webcore variables, which are additional variables WaME has added for static values such as those for
        defining the Ford Blue color from the Ford Design System, as well as the Ford Antenna font files
      - The PrimeNG Designer '_variables' file from the sample 'mytheme'.
      - The PrimeNG Designer Components SCSS
      - The custom webcore overrides and extensions build by WaME
      - Anything your team adds to this app-theme-basic folder
   In a large number of cases, custom styles built by app teams can be added in component css/scss in the /src/app
   folder. If deliberate overrides or changes are required to the theme itself, those changes should be made in the
   extensions referenced app-theme-advanced folder here in the theme.
*/


/*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
    Webcore Variables
    _________________
    Allows us to have custom fonts (Ford Antenna) and a few custom colors such as
    those coming from the Ford Design System.

    NOT for App teams to edit
~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/

@import '../webcore/webcore-variables';


/*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
    PrimeNG Designer Variables
    __________________________
    Original variables from Prime Designer, with WaME determined values.

    App Teams are free to edit values if they want custom colors, sizing, etc.
~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
@import 'variables';


/*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
    PrimeNG Designer Components
    _________________________
    Everything under Prime Designer

    NOT for App teams to edit
~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
@import '../designer/components';  // default styles from PrimeNG Designer


/*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
    Webcore Components
    __________________

    Everything under webcore, including overrides from the extensions folder
    Only for WaME team to maintain and edit

    NOT for App Teams to edit
~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
@import '../webcore/components';


/*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
    Your App Theme

    App Theme overrides, includes everything from the app-theme-basic folder
    This folder is where app teams maintain any custom or
    overriding rules they want above and beyond what we provide
~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
@import 'components';
