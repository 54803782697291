.ui-selectbutton {
    .ui-button {
        text-transform: capitalize;

        &.ui-state-active:first-child {
            background: $highBgColor;
            color: $highColor;
            border-color: $highBgColor;
        }

        &.ui-state-active:not(.ui-state-disabled):hover:first-child {
            background: $highBgColor;
            color: $highColor;
            border-color: $highBgColor;
        }
    }
}
