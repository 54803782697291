.ui-tooltip {
	max-width: none;

	.ui-tooltip-text {
		width: auto;
		min-height: 20px;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		font-size: 12px;
		padding: 3px 10px;
		border-radius: 4px;
		box-shadow: 0 4px 8px 0 rgba(17, 43, 78, 0.16), 0 0 2px 0 rgba(17, 43, 78, 0.08);
		border: solid 1px rgba(17, 43, 78, 0.04);
		background-color: $tooltipBgColor;
	}

	.ui-tooltip-arrow {
		display: none;
	}

	&.sidebar-tooltip {
		&.ui-tooltip-top {
			margin-top: -10px;
		}

		.ui-tooltip-text {
			padding: 12px 20px;
			border-radius: 8px;
			background: $primaryColor;
		}

		.ui-tooltip-arrow {
			display: block;
			border-top-color: $primaryColor;
			bottom: -8px;
			left: 50%;
			margin-left: -0.35em;
			border-width: 0.85em 0.35em 0;
		}
	}
}
