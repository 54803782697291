fieldset {
	padding: 0;
	margin: 0;
	border: 0;

	legend {
		font-weight: 700;
		font-size: 1rem;
	}
}

// labels unlike the legend tag do not always have an associated fieldset
label {
	font-weight: 700;
	font-size: 1rem;
}

.form-actions {
	// TODO is this only for JAB?
	// border-top for cancel and continue buttons on create booking page
	border-top: 1px solid #e5e5e5;
	padding: 1em;
	margin: 0 -.3125em .3125em -.3125em;
}

/* horizontal form styling */
.dl-horizontal dt {
	text-align: right;
	padding-right: 10px !important;
}

/*.required label:before,label.required:before,legend.required:before,*/
label.required-star:before,
legend.required-star:before
{
	color: $red-star;
	content: ' * ';
	display:inline;
}
/* end of horizontal form styling */
