/*
Provide any app specific custom or override footer rules you want
above and beyond what Web Enablement provides in this file
 */
footer,
#app-footer {
    padding: 0.25em 1em;
    width: 100%;
    flex: 0 0 auto;
    background-image: linear-gradient(to bottom, $FDS-surface 0, $white 100%);
}
