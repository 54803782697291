/*
Provide any app specific custom or override header rules you want
above and beyond what Web Enablement provides in this file
 */
#app-header {
	background: $primaryColor;
	height: 60px;
	align-items: center;
}

#app-logo {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	cursor: pointer;
}

#app-icon {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	color: $FDS-ford-blue;
	margin: 0 12px 0 24px;

	> img {
		transition: $app-header-width-transition;
		max-width: 75px;
	}
}

#app-name {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	padding: 0.25em 0 0 0.5em;

	h1 {
		margin: 0;
		font-family: $fontFamilyFordAntennaLight;
		font-style: normal;
		font-weight: 300;
		font-size: 1.125rem;
	}
}

app-nav {
	display: flex;
	align-items: center;

	p-menubar {
		width: 100%;
	}
}

@media screen and (min-width: $breakpoint-md) {
	app-nav {
		display: unset;
	}
}

@media screen and (min-width: $breakpoint-md) {
	#app-sidebar {
		display: none;
	}
}
