@import "variables";

.ui-chkbox {
    margin-left: 10px;

    .ui-chkbox-box {
        border-radius: 2px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid $accordionBorderColor;

        .ui-chkbox-icon {
            font-size: 12px;
        }

        &.ui-state-focus {
            box-shadow: none;
            outline: none;
        }
    }
}

.ui-chkbox .ui-chkbox-box.ui-state-active:not(.ui-state-disabled):hover {
    background-color: $buttonHoverBgColor;
}

.ui-chkbox-label {
    font-size: 14px;
    line-height: 2.57;
    font-weight: 300;
    letter-spacing: normal;
    color: $primaryColor;
    font-family: $fontFamilyFordAntennaLight;
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
}
