.ui-inputtext {
	font-size: $fontSize;
	font-family: $fontFamilyFordAntennaLight;
	color: $primaryColor;
	background: $FDS-light-gray;
	padding: $inputPadding;
	border: $inputBorder;
	@include border-radius(4px);
	font-weight: 300;
	line-height: 1.43;

	&:enabled:hover:not(.ui-state-error) {
		border-color: transparent;
	}

	&:enabled:focus:not(.ui-state-error) {
		@include shadow(none);
		border: 1px solid $inputFocusBorderColor;

		&.ng-touched.ng-invalid {
			border: $inputErrorBorder;
		}
	}

	&.ng-dirty.ng-invalid {
		border: $inputErrorBorder;

		&:focus {
			border: $inputErrorBorder;
		}
	}
}

input[type="text"],
input[type="email"],
input[type="number"] {
	border: 1px solid $FDS-light-gray;
}

input[type="email"].no-border,
input[type="text"].no-border {
	border: transparent;

	&:enabled:focus:not(.ui-state-error) {
		@include shadow(none);
		border: transparent;
	}
}

input[type="text"],
input[type="number"],
input[type="email"] {
	width: 100%;
}
