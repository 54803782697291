.ui-radiobutton {
	margin-right: 20px;
	line-height: 20px;

	.ui-radiobutton-box {
		border: none;
		background-color: transparent;

		&:not(.ui-state-disabled):not(.ui-state-active):hover {
			border-color: transparent;
		}

		&.ui-state-focus {
			border: none;
			box-shadow: none;
		}

		.ui-radiobutton-icon {
			color: $primaryColor;

			&:before {
				content: "\e909";
				display: inherit;
				line-height: 12px;
			}
		}
	}


	&-box:not(.ui-state-active) .ui-radiobutton-icon {
		display: none;

		&:before {
			content: "";
		}
	}
}

.ui-radiobutton-label {
	font-family: $fontFamilyFordAntennaLight;
	font-size: 14px;
	font-weight: 300;
	line-height: 2.57;
	color: $primaryColor;
	cursor: pointer;
	height: 36px;
	display: inline-block;
}

.p-radiobutton.ng-dirty.ng-invalid .ui-radiobutton-box {
	border: none;
}
