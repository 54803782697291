.ui-overlaypanel {
	background-color: $panelContentBgColor;
	color: $panelContentTextColor;
	padding: 16px 20px;
	margin: 0;
	border: $overlayContentBorder;
	cursor: default;
	min-width: 100px;
	color: $primaryColor;
	font-size: 14px;
	line-height: 1.43;
	@include border-radius(4px);
	@include shadow((0 4px 8px 0 rgba(17, 43, 78, 0.16), 0 0 2px 0 rgba(17, 43, 78, 0.08)));

	.ui-overlaypanel-content {
		padding: $panelContentPadding;
	}

	.ui-overlaypanel-close {
		background-color: $overlayPanelCloseIconBgColor;
		color: $overlayPanelCloseIconColor;
		top: 12px;
		right: 12px;
		width: 12px;
		height: 12px;
		line-height: 12px;
		@include border-radius(0);

		&:hover {
			color: $overlayPanelCloseIconHoverColor;
		}

		&:focus {
			@include shadow(none);
		}
	}

	&:before {
		content: none;
	}

	&:after {
		content: none;
	}

	&:before {
		border-color: rgba($overlayContentBorderColor, 0);
		border-bottom-color: $overlayContentBorderColor;
	}

	&.ui-overlaypanel-flipped {
		&:after {
			border-top-color: $panelContentBgColor;
		}

		&:before {
			border-top-color: $overlayContentBorderColor;
		}
	}
}
