
.ui-menu {
	border-radius: 4px;
	border: solid 1px rgba(17, 43, 78, 0.04);
	background-color: $menuBorder;
	min-width: 200px;

	&.ui-menu.ui-shadow {
		@include shadow((0 4px 8px 0 rgba(17, 43, 78, 0.16), 0 0 2px 0 rgba(17, 43, 78, 0.08)));
	}

	.ui-menuitem-link {
		padding: $menuitemPadding;
		color: $menuTextColor;

		&.ui-state-active{
			.ui-menuitem-text{
				color: $menuitemTextColor;
			}
		}

		&:hover{
			background-color: transparent;

			.ui-menuitem-text{
				color: $menuitemHoverTextColor;
			}
		}

		&:focus {
			@include shadow(none)
		}
	}

	.ui-menuitem {
		margin: $menuitemMargin;
	}

	&.ui-shadow {
		border: $overlayMenuBorder;
		@include shadow($overlayMenuShadow);
	}

	.ui-submenu-header {
		margin: $submenuHeaderMargin;
		padding: $menuitemPadding;
		color: $panelHeaderTextColor;
		font-size: 12px;

		background-color: $panelHeaderBgColor;
		font-weight: $panelHeaderFontWeight;
		border: 0 none;
		border-radius: 2px;
		text-transform: uppercase;
	}

}
