.ui-dropdown {
	border: none;
	@include transition($inputTransition);

	&:not(.ui-state-disabled).ui-state-focus {
		@include shadow(none)
	}
}

.ui-dropdown.outline-dropdown {
	background: $inputBgColor;

	.ui-inputtext {
		background-color: transparent;
	}

	.ui-dropdown-label {
		color: $primaryColor;
		font-size: 22px;
		letter-spacing: -0.25px;
		padding: 10px 30px 10px 14px;
	}

	.ui-dropdown-trigger-icon {
		font-size: 10px;
		font-weight: bold;
	}

	&.ui-dropdown-open {
		.ui-dropdown-label {
			color: $menuitemHoverTextColor;
		}

		.ui-dropdown-trigger {
			.ui-dropdown-trigger-icon {
				color: $menuitemHoverTextColor;
			}
		}
	}

	&:hover {
		.ui-dropdown-label {
			color: $menuitemHoverTextColor;
		}

		.ui-dropdown-trigger {
			.ui-dropdown-trigger-icon {
				transform: none;
				color: $menuitemHoverTextColor;
			}
		}
	}

	.ui-dropdown-panel {
		border-radius: 4px;
		border: none;
		background-color: $menuBorder;
		min-width: 200px;
		@include shadow((0 4px 8px 0 rgba(17, 43, 78, 0.16), 0 0 2px 0 rgba(17, 43, 78, 0.08)));

		.ui-dropdown-items-wrapper {
			max-height: 300px !important;
			height: 100%;
		}

		.ui-dropdown-items {
			padding: $inputListPadding;

			> :last-child {
				.ui-dropdown-item.ui-state-highlight {
					border-radius: 0 0 4px 4px;
				}
			}

			> :first-child {
				.ui-dropdown-item.ui-state-highlight {
					border-radius: 4px 4px 0 0;
				}
			}

			.ui-dropdown-item, .ui-dropdown-item-group {
				font-size: 18px;
				padding: 12px 12px 12px 24px;
				font-weight: 300;
				@include border-radius(0);
				font-family: $fontFamilyFordAntennaLight;

				&.ui-state-highlight {
					color: $inputListItemHighlightTextColor;
					background-color: $menuitemHoverTextColor;
				}

				&:not(.ui-state-highlight):not(.ui-state-disabled):hover {
					color: $menuitemHoverTextColor;
					background-color: transparent;
				}

			}
		}
	}

}

.ui-dropdown:not(.outline-dropdown) {
	width: 100%;

	.ui-dropdown-label-container {
		width: 100%;
	}

	&.hide-icon {
		.ui-dropdown-trigger {
			display: none;
		}
	}

	&.editable input {
		border: 1px solid transparent;

		&:enabled:hover:not(.ui-state-error) {
			border-color: transparent;
		}

		&:enabled:focus:not(.ui-state-error) {
			@include shadow(none);
			border: 1px solid $inputFocusBorderColor;

			&.ng-touched.ng-invalid {
				border: $inputErrorBorder;
			}
		}

		&.ng-dirty.ng-invalid {
			border: $inputErrorBorder;

			&:focus {
				border: $inputErrorBorder;
			}
		}

		&.ui-dropdown-label {
			cursor: text;
		}
	}

	&.editable.hide-icon input {
		padding: 7px 28px 7px 9px;
	}

	.ui-dropdown-items {
		padding: $inputListPadding;

		> :last-child {
			.ui-dropdown-item.ui-state-highlight {
				border-radius: 0 0 4px 4px;
			}
		}

		> :first-child {
			.ui-dropdown-item.ui-state-highlight {
				border-radius: 4px 4px 0 0;
			}
		}

		.ui-dropdown-item, .ui-dropdown-item-group {
			font-size: 14px;
			padding: 4px 10px;
			font-weight: 500;
			@include border-radius(0);
			font-family: $fontFamilyFordAntennaMedium;
			line-height: 1.43;

			&.ui-state-highlight {
				color: $inputListItemHighlightTextColor;
				background-color: $menuitemHoverTextColor;
			}

			&:not(.ui-state-highlight):not(.ui-state-disabled):hover {
				color: $menuitemHoverTextColor;
				background-color: transparent;
			}

		}
	}
}
