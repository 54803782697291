.ui-tieredmenu {
	padding: $menuPadding;
	background-color: $transparent;

	.ui-menuitem {
		margin: 0;
	}

	.ui-menuitem-link {
		padding: $menuitemLinkPadding;

		.ui-menuitem-text {
			color: $primaryColor;
			white-space: pre;
		}

		&.ui-menuitem-link-active {
			.ui-menuitem-text {
				font-size: 14px;
				font-weight: 500;
				color: #0475b3;
			}

			&:before {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				width: 4px;
				height: 40px;
				background-color: $OnHoverMenuItemTextColor;
				color: $OnHoverMenuItemTextColor;
			}
		}

		&:hover .ui-menuitem-text {
			color: $OnHoverMenuItemTextColor;
		}

		&:hover {
			background: $transparent;
		}

		&:focus {
			@include shadow(none)
		}

	}

}
