.ui-accordion {
    padding-left: 24px;
    margin-top: 6px;

    .accordion-tab {
        &:last-child {
            .ui-accordion-content-wrapper {
				&.ui-accordion-content-wrapper-overflown {
                	border-bottom: 1px solid $accordionBorderColor;
				}
            }
        }
    }

    .ui-accordion-header {
        height: 60px;
        border-top: 1px solid $accordionBorderColor;
        border-radius: 0px;

        display: flex;
        align-items: center;

        a {
            position: relative;
            text-decoration: none;
            text-transform: capitalize;
            display: flex;
            align-items: center;
            width: 100%;
            height: 100%;
            border-radius: 0px;
            color: $primaryColor;
            font-weight: 500;
            padding-left: 0px;
            color: $primaryColor;
            font-family: $fontFamilyFordAntennaMedium;

            .ui-accordion-toggle-icon {
                position: absolute;
                right: 24px;
                font-size: 10px;
                transform: rotate(90deg);
            }

            &:focus {
                box-shadow: none;
            }
        }

        &:not(.ui-state-active) {
            &:not(.ui-state-disabled) {
                &:hover {
                    a {
                        background-color: transparent;
                        border: none;
                        color: $primaryColor;

                        .ui-accordion-toggle-icon {
                            color: $OnHoverMenuItemTextColor;
                        }
                    }
                }
            }
        }

        &:not(.ui-state-disabled) {
            &.ui-state-active {
                a {
                    background-color: transparent;
                    border: none;
                    color: $primaryColor;

                    .ui-accordion-toggle-icon {
                        color: $primaryColor;
                        transform: rotate(180deg);
                    }
                }

                &:hover {
                    a {
                        background-color: transparent;
                        border: none;
                        color: $primaryColor;

                        .ui-accordion-toggle-icon {
                            color: $OnHoverMenuItemTextColor;
                        }
                    }
                }
            }
        }
    }

    .ui-accordion-content {
        padding: 0px 24px 24px 0px;
        border: none;
        color: $primaryColor;
    }
}
