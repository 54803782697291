.ui-datepicker {
	width: 100%;
	color: $primaryColor;
	border: none;
	margin-top: 12px;

	&-calendar {
		font-family: $fontFamilyFordAntennaLight;
		font-weight: 300;
	}

	&:not(.ui-datepicker-inline) {
		@include shadow(none);
	}

	&:not(.ui-state-disabled) {
		.ui-datepicker-header {
			.ui-datepicker-prev,
			.ui-datepicker-next {
				&:focus {
					@include shadow(none);
				}
			}
		}

		table {
			thead {
				line-height: 2.57;
				color: $FDS-dark-gray;
			}

			td {
				a:not(.ui-state-active):not(.ui-state-highlight):hover {
					background-color: transparent;
				}
			}
		}

		.ui-monthpicker {
			a.ui-monthpicker-month:not(.ui-state-active):hover {
				background-color: transparent;
				color: $menuitemHoverTextColor;
			}
		}
	}

	.ui-datepicker-header {
		color: $primaryColor;
		font-family: $fontFamilyFordAntennaLight;
		font-size: 18px;
		font-weight: 300;
		line-height: 2;
		height: 24px;
		padding-left: 0;

		.ui-datepicker-prev,
		.ui-datepicker-next {
			color: $primaryColor;

			&:hover {
				color: $menuitemHoverTextColor;
			}
		}

		.ui-datepicker-prev {
			left: inherit;
			right: 48px;
		}

		.ui-datepicker-next {
			right: 10px;
		}

		.ui-datepicker-title {
			margin: 0;
			padding: 0;
			line-height: 1;
			position: absolute;
		}
	}

	table {
		font-size: $fontSize;
		margin: $calendarTableMargin;

		th {
			padding: $calendarHeaderCellPadding;

			&.ui-datepicker-weekheader {
				border-right: $inputListBorder;
			}
		}

		td {
			padding: $calendarCellDatePadding;

			> a, > span {
				@include transition(background-color $transitionDuration);
				color: $primaryColor;
				height: 36px;
				width: 36px;
				display: flex;
				justify-content: center;
				align-items: center;

				&.ui-state-active {
					color: $calendarCellDateSelectedTextColor;
					background-color: $calendarCellDateSelectedBgColor;
				}
			}

			&.ui-datepicker-today {
				> a, > span {
					background-color: $calendarCellDateTodayBgColor;
					color: $primaryColor;
					max-height: 36px;
					max-width: 36px;
					padding: 0;
					display: flex;
					justify-content: center;
					align-items: center;

					margin: 0 auto;

					&.ui-state-active {
						color: $calendarCellDateSelectedTextColor;
						background-color: $calendarCellDateSelectedBgColor;
					}


					&.ui-state-disabled {
						&:hover {
							color: $primaryColor;
						}
					}
				}
			}

			&.ui-datepicker-current-day {
				&:hover {
					> a, > span {
						color: $white;
						background-color: $menuitemHoverTextColor;
					}
				}
			}

			&:hover {
				> a, > span {
					color: $menuitemHoverTextColor;
					background-color: transparent;
				}
			}
		}
	}

	.ui-calendar {
		width: 100%;
	}
}

.month-calendar {
	width: 320px;

	.ui-datepicker-header {
		.ui-datepicker-prev {
			right: 38px;
		}

		.ui-datepicker-next {
			right: 0px;
		}
	}

	.ui-datepicker-group {
		padding-left: 11px;
	}

	.ui-monthpicker  {
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;

		.ui-monthpicker-month {
			@include transition(background-color $transitionDuration);
			text-decoration: none;
			color: $primaryColor;
			width: 20%;
			height: 28px;
			width: 65px;
			margin-top: 16px;
			border-radius: 14px;

			display: flex;
			justify-content: center;
			align-items: center;
		}
	}
}
