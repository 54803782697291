.ui-table {
	table {
		table-layout: auto;
	}

	.ui-table-thead {
		tr {
			width: 100%;

			th {
				background-color: transparent;
				color: $primaryColor;
				height: 44px;
				border: none;
				text-align: left;
				padding: .25em .5em;
				width: auto;

				&:hover {
					background-color: transparent;
				}

				.header-name {
					width: 100%;
					height: 100%;
					display: flex;
					justify-content: flex-start;
					align-items: center;

					.sort-icon-wrap {
						display: flex;
						flex-direction: column;
						justify-content: space-around;

						height: 100%;
						padding: 7px 10px;

						.sort-icon {
							width: 10px;
							height: 6px;
							font-size: 9px;
							font-weight: 900;
							transform: rotate(90deg);

							&.active {
								color: $buttonBgColor;
							}
						}
					}
				}

				&.avatar {
					width: 55px;
				}
			}
		}
	}

	.ui-sortable-column:not(.ui-state-highlight) {
		&:hover {
			background-color: transparent;
			color: $primaryColor;
		}
	}
	.ui-sortable-column {
		&.ui-state-highlight {
			background-color: transparent;
			color: $primaryColor;

			&:hover {
				background-color: transparent;
				color: $primaryColor;
			}
		}

		&:focus {
			outline: none;
			box-shadow: none;
		}
	}

	.ui-table-tbody {
		tr {
			background-color: $white;
			border-top: 1px solid $borderGrayColor;
			border-bottom: 1px solid $borderGrayColor;

			width: 100%;

			&:nth-child(even) {
				background-color: $white;
			}
			&:last-child {
				border-bottom: none;
			}

			td {
				height: 44px;
				border: none;
				width: auto;
				min-width: 135px;
				white-space: nowrap;
				padding-right: 50px;

				&:first-child {
					padding-right: 0.5em;
					min-width: auto;
				}

				&:last-child {
					width: 100%;
				}
			}
		}
	}
}
