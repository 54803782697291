/*
Provide any app specific custom or override site-wide rules you want
above and beyond what Web Enablement provides in this file
 */
html {
	height: 100%;
	margin: 0;
}

body {
	display: flex;
	flex-direction: column;
	font-family: $fontFamilyFordAntenna;
	font-size: $fontSize;
	font-style: normal;
	font-weight: 400;
	margin: 0 auto;
	max-width: 3840px;
	padding: 0;
	height: 100%;

	.ui-widget-overlay.ui-sidebar-mask:first-child {
		background-color: $maskBgColor;
	}

}

body .ui-sidebar-mask:first-of-type {
	background-color: #4cae4c;
}

@keyframes ui-progress-spinner-color {
	// disable change color
}

.ui-progress-spinner-circle {
	stroke: #0475b3;
}

strong {
	font-family: $fontFamilyFordAntennaMedium;
	font-size: 14px;
	font-weight: 500;
}
